body {
  overflow: hidden;
  background-color: #fff !important;
}

.header-message {
  margin-top: 20px;
}

.chat-bubble {
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: flex;
}

.chat-typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: green;
  border-radius: 50%;
  height: 5px;
  margin-right: 4px;
  width: 5px;
  display: inline-block;
}

.chat-typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.chat-typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.chat-typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.chat-typing .dot:last-child {
  margin-right: 0;
}

.chat {
  margin-left: 23px;
  font-size: 24px;
  font-weight: 500;
  margin-top: 15px;
}

.Time-size {
  font-size: 12px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

.recent {
  font-size: 17px;
  padding-left: 23px;
  margin-top: 12px;
}
.chat-box-cancel {
  pointer-events: none;
}
.chat-right-bubble .adds {
  top: auto;
}
.typing {
  margin-bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  top: -40px;
}

.chat-left-bubble .adds {
  top: auto;
  bottom: 44px;
  right: 15px;
}

.adds {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100px;
}

.client-adds {
  position: absolute;
  bottom: 10px;
  right: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 80px;
  background-color: rgba(0, 0, 0, 0.3);
  height: 80px;
}

.image-plus-items {
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  border: 1px solid red;
  height: 100%;
}

.hover:hover {
  background-color: rgb(238, 235, 235);
}

textarea:focus,
input:focus {
  outline: none;
  border: none;
  color: none;
}

.search_input {
  background-color: #e6ebf5;
  padding: 10px;
  vertical-align: middle;
  width: 69%;
  border-radius: none;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
  margin-top: 11px;
  vertical-align: middle;
  height: 42px;
  margin-left: 4px;
  margin: 0;
}

.chat-list-headerr {
  padding: 30px;
  background-color: #ffff;
}

.time_chat {
  margin-bottom: 29px;
  font-size: 11px;
  position: relative;
}

.scroll-box::-webkit-scrollbar {
  width: 5px;
}

.scroll-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

.scroll-box::-webkit-scrollbar-thumb {
  background-color: #cad1d8;
  border-radius: 10px;
}

.scroll-box::-webkit-scrollbar-thumb:hover {
  background-color: #d7dee7;
}

.preview-container {
  /* overflow-x: scroll; */
  overflow-y: hidden;
  display: flex;
  z-index: 1;
  padding-left: 22px;
  bottom: 42px;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 10px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 52px;
  background-color: rgb(241 241 241 / 90%);
}

.tag-container {
  overflow-y: hidden;
  display: flex;
  z-index: 1;
  bottom: 42px;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 10px;
  padding-left:10px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  cursor: pointer;
}
.settingprofilename {
  padding-top: 9px;
}

.preview-container::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

.preview-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

.preview-container::-webkit-scrollbar-thumb {
  background-color: #a2a4a8;
  border-radius: 10px;
}

.preview-container::-webkit-scrollbar-thumb:hover {
  background-color: #a2a4a8;
}

.chat-box-container {
  position: relative;
}

.chat-right-container {
  display: flex;
  flex-direction: column;
}

.message-container {
  height: 80vh;
}

.chat-left-container {
  height: 88vh;
}

.file_attachcontainerr {
  position: relative;
  width: 13%;
}

.btn-closeContainer {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  text-align: center;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-file {
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
  object-fit: cover;
}

.imgClose-Icon {
  color: red;
  position: absolute;
  font-size: 12px;
}

.file_attachcontainerr:hover .selected-file {
  opacity: 0.3;
}

.hover_img {
  float: left;
  position: relative;
}

.file_attachcontainerr:hover .btn-closeContainer {
  opacity: 1;
}

#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}

.online_status {
  font-size: 14px;
  position: absolute;
  margin-left: 30px;
  margin-top: -11px;
  z-index: 1;
}

.delete-messgae {
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.scroll-box {
  height: 80vh;
  overflow-y: auto;
  border: 0px solid gainsboro;
  margin-top: 0;
  overflow-x: hidden;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  position: relative;
  background-color: #ffff;
}

.author_left {
  height: "100%";
}

.recent {
  font-size: 17px;
  padding-left: 23px;
  margin-top: 12px;
  background-color: #ffff;
}

.author_left svg.MuiSvgIcon-root path:nth-child(1) {
  opacity: 2 !important;
}

svg.MuiSvgIcon-root path:nth-child(2) {
  color: #fbf2f2 !important;
}

.chat-scrollpage {
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 10px;
  height: calc(75vh - 1rem);
  position: relative;
  padding-bottom: 3.5rem;
  background-color: #fff;
}

.chat-scrollpage::-webkit-scrollbar {
  width: 5px;
}

.chat-scrollpage::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

.chat-scrollpage::-webkit-scrollbar-thumb {
  background-color: #cad1d8;
  border-radius: 10px;
}

.chat-scrollpage::-webkit-scrollbar-thumb:hover {
  background-color: #d7dee7;
}

.profile_name {
  margin-top: 5px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  margin-left: 10px;
}

.border-box {
  border-bottom: 2px solid #f0eff5;
}

.profile_icon {
  display: flex;
  flex: 0.9;
}

.right_time {
  margin-top: -56px;
  font-size: 11px;
  z-index: 1;
  margin-left: 27px;
  color: #ccd7bf;
}

.Left-timediv {
  display: flex;
  flex: row;
  float: right;
  margin-right: 12px;
  font-size: 11px;
  color: rgb(33, 35, 31);
}

.chatmessage {
  margin-top: -80px;
  width: 200px;
  position: relative;
  margin-left: -15px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.More-icon-left {
  margin-top: 2px;
  background-color: red;
  z-index: 9;
  position: absolute;
  left: 10px;
  box-shadow: 40px 40px 40px 60px rgb(235, 236, 237) inset;
}

.dotedlist {
  position: relative;
}

.More-icon-right {
  background-color: red;
  z-index: 9;
  margin-top: -44px;
  box-shadow: 40px 40px 40px 60px rgb(235, 236, 237) inset;
  position: absolute;
  right: 280px;
}

.menu-listedd {
  padding-right: 30px;
}

.inpt {
  background-color: #e6ebf5;
  padding: 20px;
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 0;
  height: 8px;
  margin-left: -15px;
}

.Footer-icons {
  display: flex;
  flex: 0.4;
  /* flex-direction: row; */
  /* justify-content: flex-end; */
  /* padding-right: 20px; */
}

.bottomin input {
  width: 100%;
}

.bottomin {
  flex: 0.6;
}

/* 
    .message_left {
      border-radius: 10px;
      padding-top: 10px;
      padding-bottom: 31px;
      padding-left: -5px;
      padding-right: 12px;
      border-bottom-left-radius: 0px;
    } */

.rightsecond {
  border-radius: 10px;
  padding-bottom: 31px;
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 10px;
  border-bottom-right-radius: 0px;
}

.divider {
  background-color: #ccd7bf;
  width: 125%;
  border-radius: 4px;
  background-color: #e6ebf5;
}

.search-nav {
  z-index: 9;
  background-color: #197abf;
}

.search-top-box {
  position: absolute;
  inset: 0px 90px auto auto;
  margin: 0px;
  transform: translate(0px, 42px);
}

.pop-up {
  background-color: white;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
  padding: 7px;
  border-radius: 4px;
  margin-right: 15px;
  margin-top: -44px;
}

.pop-up {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.pop-input-box {
  padding: 5px;
  padding-right: 41px;
  background-color: rgb(230, 235, 245);
  border-radius: 8px;
  border: 0px;
}

.drop-down-menu {
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  z-index: 1000;
  background-color: #fff;
  position: absolute;
  inset: -11px 7px auto auto;
  margin: 0px;
  transform: translate(0px, 42px);
  width: 130%;
  top: 50px;
}

.drop-down-menu {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.More-drop-down-right {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.More-drop-down-left {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.react-input-emoji--button svg {
  fill: #3399cc !important;
}

.support_system_css {
  display: "flex";
  align-items: "center";
  justify-content: "space-between";
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.blink {
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #6cad96;
  }

  28% {
    transform: translateY(-7px);
    background-color: #9ecab9;
  }

  44% {
    transform: translateY(0px);
    background-color: #b5d9cb;
  }
}

.input-container {
  padding: 10;
  width: "100%";
}

/* accept notification */

.chat-accept-btn {
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

.chat-cancel-btn {
  -webkit-animation: glowing-red 1500ms infinite;
  -moz-animation: glowing-red 1500ms infinite;
  -o-animation: glowing-red 1500ms infinite;
  animation: glowing-red 1500ms infinite;
}
.profile-accordians-container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 54vh;
  padding: 1vw;
}

.profile-accordians-container::-webkit-scrollbar {
  width: 5px;
}

.profile-accordians-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

.profile-accordians-container::-webkit-scrollbar-thumb {
  background-color: #cad1d8;
  border-radius: 10px;
}

.profile-accordians-container::-webkit-scrollbar-thumb:hover {
  background-color: #d7dee7;
}
.accordians-container {
  margin-top: 20px;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 58vh;
}

.button-ani {
  color: #ffffff;
  cursor: pointer;
  width: 80%;
}

.whole-footer {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 100%;
  position: relative;
}

.text-emoji {
  width: 100%;
  padding: 4px;
}

.footer-imgIcon {
  margin-right: 12px;
  margin-top: 11px;
}

.footer-attachIcon {
  margin-right: 12px;
  margin-top: 11px;
}

.footer-sendIcon {
  margin-right: 16px;
  margin-top: 12px;
  padding-bottom: 15px;
}

.notication-card {
  width: 35%;
  height: 47vh;
  margin-left: 22px;
  margin-top: 92px;
  position: fixed;
}

.Dawer-width {
  width: 420px;
  overflow-y: hidden;
}

.Dawer-closeIcon {
  position: absolute;
  right: 39px;
  top: 25px;
  cursor: pointer;
}

.delete-popup {
  z-index: 1;
  position: absolute;
  width: 404%;
  right: 433%;
  top: 228px;
}

.Logout-Admin-proflie {
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  z-index: 9;
  background-color: #fff;
  position: absolute;
  left: 24%;
  padding: 12px;
  top: 64px;
}

.profile-status {
  position: absolute;
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  z-index: 1000;
  background-color: #fff;
  width: 10%;
  margin-left: 70px;
  top: 40%;
  padding: 2px;
  color: #6b7a80;
}

.profile-status {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.account-profile {
  display: flex;
  width: 5vw;
  height: 5vw;
  margin: auto;
  margin-top: 5vw;
  border-radius: 5vw;
  justify-content: center;
  align-items: center;
  background-color: #e4e8eb;
}

.chat-left-bubble,
.chat-right-bubble {
  max-width: 60%;
  position: relative;
}
.account-profile {
  width: 0vw;
  margin-top: 3vw;
}
.chat-cancel-btn {
  background-color: #6cad96;
  cursor: pointer;
  /* width: 80%; */
}

@keyframes glowing {
  0% {
    background-color: green;
    box-shadow: 0 0 3px green;
  }

  50% {
    background-color: green;
    box-shadow: 0 0 15px green;
  }

  100% {
    background-color: green;
    box-shadow: 0 0 0px green;
  }
}

@keyframes glowing-red {
  0% {
    background-color: red;
    box-shadow: 0 0 3px red;
  }

  50% {
    background-color: red;
    box-shadow: 0 0 15px red;
  }

  100% {
    background-color: red;
    box-shadow: 0 0 0px red;
  }
}
.settingprofilename {
  padding-top: 9px;
}

/* 
  
  @media only screen and (min-width:1400px) {
    .chat-scrollpage {
      height: 82vh;
    }
  }
  
  @media only screen and (min-width:1500px) {
    .chat-scrollpage {
      height: 82vh;
    }
  }
  
  @media only screen and (min-width:1600px) {
  
    .chat-scrollpage {
      height: 82vh;
    }
  
  
    .text-emoji {
      width: 83%;
    }
  }
  
  @media only screen and (min-width:1700px) {
    .chat-scrollpage {
      height: 82vh;
  
    }
  
    .text-emoji {
      width: 84%;
    }
  }
  
  
  @media only screen and (min-width:1800px) {
  
    .chat-scrollpage {
      height: 82vh;
    }
  
    .text-emoji {
      width: 85%;
    }
  }
  
  @media only screen and (min-width:1920px) {
    .chat-scrollpage {
      height: 83vh;
    }
  
    .text-emoji {
      width: 85%;
    }
  
  }
  
  @media only screen and (max-width:1300px) {
    .chat-scrollpage {
      height: 76vh;
  
    }
  
    .delete-popup {
      right: 145%;
    }
  
    .file_attachcontainerr {
      width: 17%;
  
    }
  
  } */
@media only screen and (max-width: 1300px) {
  .profile-accordians-container {
    height: 56vh;
  }
  .delete-popup {
    right: 178px;
  }
}

@media only screen and (max-width: 1200px) {
  /* .scroll-box {
      height: 77vh;
    }
  
    .chat-scrollpage {
      height: 77vh;
    }
  
    .delete-popup {
      right: 115%;
    } */

  /* 
    .previews-container {
      width: 38%;
      left: -92px;
      right: -278px;
      bottom: 53px;
  
    } */

  .delete-popup {
    right: 178px;
  }

  .notication-card {
    width: 47%;
  }
  .adminprofileName {
    padding-top: 8%;
  }
  .profile-status {
    width: 15%;
  }
  .profile-accordians-container {
    height: 400px;
  }
  .file_attachcontainerr {
    width: 23%;
  }
  .settingprofilename {
    padding-top: 19px;
  }
}

@media only screen and (max-width: 899px) {
  .show-chat {
    overflow-x: hidden;
    overflow-y: hidden;
    transform: translate(100%, 0);
    transition: 0.5s;
    position: fixed;
    top: "-10px";
    width: 100%;
    height: 100%;
  }

  .chat {
    transform: translate(0, 0);
    z-index: 9;
    width: 100%;
    height: 100%;
  }

  .footer-sendIcon {
    margin-top: 11px;
    margin-right: 21px;
  }

  .drop-down-menu {
    /* inset: -11px 25px auto auto; */
    top: 62px;
    /* width: 188%; */
    /* right: 4px; */
    width: 380%;
  }

  .Bottom-nav {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0px;
    z-index: 1;
    height: -3px;
    /* height: 10px; */
  }

  /* .profile-nav {
      position: absolute;
      bottom: -52px;
      left: 18px;
      z-index: 1;
      height: 60px;
      display: flex;
      align-items: end;
      width: 67px;
      background-color: white;
    } */

  .side-nav-profileMore {
    top: 80px;
    left: 77%;
  }

  .chat-scrollpage {
    height: 80vh;
  }

  /* .delete-popup {
      z-index: 1;
      position: absolute;
      width: 284%;
      right: 150%;
      top: 218px;
  } */
  /* .delete-popup {
      z-index: 1;
      position: absolute;
      width: 380%;
      right: 283%;
      top: 218px;
    } */

  .delete-popup {
    right: 466%;
    width: 788%;
  }

  .pop-up {
    margin-right: 16px;
    margin-top: -50px;
  }

  /* .previews-container {
      width: 68%;
      left: -334px;
      right: -123px;
      bottom: 48px;
    } */

  .scroll-box {
    height: 69vh;
  }

  .notication-card {
    width: 56%;
    /* height: 64vh;
      margin-left: 22px;
      margin-top: 77px;
      position: fixed; */
  }

  .Logout-Admin-proflie {
    left: 74%;
  }

  .profile-status {
    top: 38%;
    width: 18%;
  }

  .account-profile {
    height: 9vw;
  }

  .file_attachcontainerr {
    width: 23%;
  }
  .adminprofileName {
    padding-top: 2%;
  }
  .profile-accordians-container {
    height: 320px;
  }
  .settingprofilename {
    padding-top: 29px;
  }
}

@media only screen and (max-width: 767px) {
  /* .chat-scrollpage {
      height: 79vh;
    } */

  /* .delete-popup {
      z-index: 1;
      position: absolute;
      width: 255%;
      right: 40%;
      top: 206px;
  } */
  .delete-popup {
    right: 77%;
  }

  .pop-up {
    margin-right: -15px;
    margin-top: -37px;
  }

  /* .profile-nav {
      position: absolute;
      bottom: -52px;
      left: 18px;
      z-index: 999;
      height: 60px;
      display: flex;
      align-items: end;
      width: 67px;
      background-color: white;
    } */

  .side-nav-profileMore {
    top: 80px;
    left: 62%;
  }

  .profile_swipe.MuiBox-root.css-1y0el87 {
    width: 300px;
  }

  .mbclose-icon {
    margin-left: 257px;
  }

  .pop-up {
    margin-top: 2px;
  }

  /* .previews-container {
      width: 49%;
      left: -333px;
      right: -123px;
      bottom: 56px;
    } */

  .Bottom-nav {
    position: fixed;
    /* left: 0; */
    /* right: 0; */
    width: 100%;
    bottom: 0px;
    z-index: 1;
    height: -3px;
    /* height: 10px; */
  }

  .notication-card {
    width: 60%;
    /* height: 64vh;
      margin-left: 22px;
      margin-top: 77px;
      position: fixed; */
  }
  .accordians-container {
    height: 52vh;
  }

  .Logout-Admin-proflie {
    left: 64%;
  }

  .profile-status {
    /* top: 210px; */
    top: 35%;
    width: 19%;
  }

  .account-profile {
    height: 15vw;
  }

  .file_attachcontainerr {
    width: 27%;
  }
  .profile-accordians-container {
    height: 350px;
  }
}

@media only screen and (max-width: 480px) {
  .footer-attachIcon {
    margin-right: -4px;
    margin-top: 11px;
  }

  .footer-imgIcon {
    margin-right: -4px;
    margin-top: 11px;
  }
  .profile-accordians-container {
    height: 368px;
  }
  /* .profile-nav {
      position: absolute;
      bottom: -52px;
      left: 18px;
      z-index: 999;
      height: 60px;
      display: flex;
      align-items: end;
      width: 67px;
      background-color: white;
    } */

  .side-nav-profileMore {
    top: 77px;
    left: 50%;
  }

  .nav_icons {
    display: flex;
  }

  /* .Bottom-nav {
      position: fixed;
      left: 0;
      right: 0;
      width: 100%;
      bottom: 0px;
      z-index: 1;
      height: -3px;
      height: 10px;
    } */

  .pop-up {
    margin-right: -16px;
    margin-top: -50px;
  }

  .gg {
    overflow-y: scroll;
    height: 62.5vh;
    padding: 15px;
  }

  /* .previews-container {
      width: 41%;
      left: -303px;
      bottom: 49px;
    } */

  .scroll-box {
    height: 70vh;
  }

  .Dawer-width {
    width: 275px;
    overflow-y: hidden;
  }

  .account-profile {
    height: 17vw;
  }

  /* .delete-popup {
    z-index: 1;
    position: absolute;
    width: 253%;
    right: 2%;
    top: 206px;
  } */
  .delete-popup {
    z-index: 1;
    right: 0%;
    /* top: 206px; */
  }

  .notication-card {
    width: 88%;
    height: 59vh;
    right: 6%;
  }

  .Logout-Admin-proflie {
    left: 53%;
  }

  .profile-status {
    top: 35%;
    width: 20%;
  }

  .file_attachcontainerr {
    width: 38%;
  }

  .chat-left-bubble,
  .chat-right-bubble {
    max-width: 75%;
  }
  .settingprofilename {
    padding-top: 39px;
  }
  .accordians-container {
    height: 49vh;
  }
}

@media screen and (max-width: 480px) and (min-width: 360px) {
  .side-nav-profileMore {
    top: 77px;
    left: 52%;
  }
  .profile-accordians-container {
    height: 368px;
  }
  .settingprofilename {
    padding-top: 39px;
  }
  .chat-left-bubble,
  .chat-right-bubble {
    max-width: 75%;
  }

  .accordians-container {
    height: 49vh;
  }
}
