  .container {
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      /* background: rgb(63, 73, 204); */
    }
    .formContainer {
      width: 400px;
      margin: 0 auto 0 auto;
      padding: 32px;
      background: lightblue;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 28px;
    }
    .input {
      width: 100%;
      padding: 12px;
      border-radius: 6px;
      /* border: 1px solid rgb(63, 73, 204); */
      font-size: 0.9rem;
    }
    .input option {
      margin-top: 20px;
    }

    body{
      background:#eee; 
      font-family: sans-serif;
    }
    
    
    .userbane {
        flex-grow: 1;
    }
    body{
      background:#eee; 
      font-family: sans-serif;
    }
    
    .msg_box {
        position: fixed;
        bottom: -5px;
        width: 308px;
        background: #fff;
        border-radius: 10px 10px 0px 0px;
      box-shadow: 0 0 7px rgb(0 0 0 / 20%);
    }
    
    .userbane {
        flex-grow: 1;
    }
    .msg_head {
        background: #fff;
        color: #333;
        padding: 8px;
        font-weight: bold;
        cursor: pointer;
        border-radius: 10px 10px 0 0;
        height: 45px;
        display: flex;
        align-items: center;
        padding: 7px 9px;
        box-shadow: 0 0 2px #d5d5d5;
        border-bottom: 1px solid #d5d5d5;
    }
    .msg_body{
      background:white;
      height:310px;
      font-size:12px;
      padding:15px;
      overflow:auto;
      overflow-x: hidden;
    }
    .msg_body::-webkit-scrollbar {
      width: 10px;
    }
    
    /* Track */
    .msg_body::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
      
    /* Handle */
    .msg_body::-webkit-scrollbar-thumb {
      background: #d5d5d5; 
    }
    
    /* Handle on hover */
    .msg_body::-webkit-scrollbar-thumb:hover {
      background: #eee; 
    }
    .msg_input{
      width: 79%;
      height: 55px;
      border: 1px solid white;
      border-top: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      resize: unset;
      float: right;
      border-top-right-radius: 5px;
      outline: 0;
    }
    .msg_input::-webkit-scrollbar-thumb {
      background: transparent; 
      border-radius: 10px;
    }
    
    /* Handle on hover */
    .msg_input::-webkit-scrollbar-thumb:hover {
      background: #b30000; 
    }
    .msg_input::-webkit-scrollbar {
      width: 5px;
    }
    
    /* Track */
    .msg_input::-webkit-scrollbar-track {
      box-shadow: unset; 
      border-radius: 10px;
    }
    .custom-file-upload {
        width: 47px;
        float: left;
        background-color: #f6f6f6;
        text-align: center;
        height: inherit;
        font-size: 21px;
        display: flex;
        align-items: center;
        border-top-left-radius: 5px;
        justify-content: center;
    }
    .msg_footer {
        padding: 0 10px;
        border-radius:5px 5px 5px 5px;
    }
    .text_body {
        border: 1px solid #d5d5d5;
        border-radius: 5px 5px 0px 0px;
        float: left;
        width: 100%;
        display: flex;
    
      }
    .close {
        float: right;
        cursor: pointer;
        
    
    }
    input#file {
        display: none;
    }
    .square{
      width: 22px;
        height: 23px;
        text-align: center;
        font-size: 16px;
        margin-left: 4px;
        line-height: 23px;
        color: #bababa;
        border: 1px solid #d5d5d5;
        border-radius: 6px;
    }
    .minimize{
      float:right;
      cursor:pointer;
      padding-right:5px;
      
    }
    
    .msg-left{
      position:relative;
      background:#e2e2e2;
      padding:5px;
      min-height:10px;
      margin-bottom:5px;
      margin-right:10px;
      border-radius:5px;
      word-break: break-all;
    }
    
    .msg-right{
      background:#d4e7fa;
      padding:5px;
      min-height:15px;
      margin-bottom:5px;
      position:relative;
      margin-left:10px;
      border-radius:5px;
      word-break: break-all;
    }
    
    /**** Slider Layout Popup *********/
    
      #chat-sidebar {
          width: 250px;
          position: fixed;
          height: 100%;
          right: 0px;
          top: 0px;
          padding-top: 10px;
          padding-bottom: 10px;
          border: 1px solid #b2b2b2;
    }
      #sidebar-user-box {
          padding: 4px;
          margin-bottom: 4px;
          font-size: 15px;
          font-family: Calibri;
          font-weight:bold;
          cursor:pointer;
    }
      #sidebar-user-box:hover {
          background-color:#999999 ;
    }
      #sidebar-user-box:after {
          content: ".";
          display: block;
          height: 0;
          clear: both;
          visibility: hidden;
    }
    #sidebar-user-box img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        float: left;
        object-fit: cover;
    }
      #slider-username{
          float:left;
          line-height:30px;
          margin-left:5px;
    }
    
    .custom-file-upload {
        width: 50px;
        float: left;
        background-color: #f6f6f6;
        text-align: center;
        height: inherit;
        font-size: 21px;
        display: flex;
        align-items: center;
        border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
        justify-content: center;
      cursor:pointer;
    }
    .msg_footer {
        padding: 0 10px;
        border-radius:5px 5px 5px 5px;
        padding-bottom: 10px;
        overflow: hidden;
    }
    .text_body {
        border: 1px solid #d5d5d5;
        border-radius:5px;
        display: flex;
    
      }
    .close {
        float: right;
        cursor: pointer;
      
    }
    .avatar span {
        display: block;
      /* background-image:url(../user-thumbnail.png); */
      background-position:center;
      background-repeat:no-repeat;
      background-size:cover;
        width: 25px;
        border-radius: 50px;
        height: 25px;
        background-color: #eee;
        margin-right: 7px;
    }
    
    
    .custom-file-upload i.fa.fa-paperclip {
        opacity: 0.5;
    }
    .sent_btn {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sent_btn span {
        opacity: 0.5;
    }
    input#file {
        display: none;
    }
    .square{
      width: 22px;
        height: 23px;
        text-align: center;
        font-size: 16px;
        margin-left: 4px;
        line-height: 23px;
        color: #bababa;
        border: 1px solid #d5d5d5;
        border-radius: 6px;
    }
    .minimize{
      float:right;
      cursor:pointer;
      padding-right:5px;
      
    }
    
    .msg-left{
      position:relative;
      background:#e2e2e2;
      padding:5px;
      min-height:10px;
      margin-bottom:5px;
      margin-right:10px;
      border-radius:5px;
      word-break: break-all;
    }
    
    .msg-right{
      background:#d4e7fa;
      padding:5px;
      min-height:15px;
      margin-bottom:5px;
      position:relative;
      margin-left:10px;
      border-radius:5px;
      word-break: break-all;
    }
    .receive_msg {
        float: left;
        width: 100%;
    }
    .send_msg p {
        text-align: right;
        background-color: #3399cc;
        display: inline-block;
        float: right;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
    }
    .receive_msg p{
      text-align: left;
        background-color: #dfeecf;
        display: inline-block;
        float: left;
        color: #333;
        padding: 10px 20px;
        border-radius: 5px;
    }
    /**** Slider Layout Popup *********/
    
      #chat-sidebar {
          width: 250px;
          position: fixed;
          height: 100%;
          right: 0px;
          top: 0px;
          padding-top: 10px;
          padding-bottom: 10px;
          border: 1px solid #b2b2b2;
    }
      #sidebar-user-box {
          padding: 4px;
          margin-bottom: 4px;
          font-size: 15px;
          font-family: Calibri;
          font-weight:bold;
          cursor:pointer;
    }
      #sidebar-user-box:hover {
          background-color:#999999 ;
    }
      #sidebar-user-box:after {
          content: ".";
          display: block;
          height: 0;
          clear: both;
          visibility: hidden;
    }
    #sidebar-user-box img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        float: left;
        object-fit: cover;
    }
      #slider-username{
          float:left;
          line-height:30px;
          margin-left:5px;
    }
    .btm_slider_wrapper {
        margin: 0 10px;
        border: 1px solid #d5d5d5;
        border-radius: 5px;
        /* float: left; */
        /* width: 100%; */
        margin-bottom: 15px;
    }
    
    .bottom_slider {
        padding: 10px;
        /* margin-bottom: 5px; */
    }
    
    .slider_content {
        display: inline-block;
        margin: 0 10px;
    }