.covo-left {
  gap: 0.5rem;
  margin-top: 20px;
}

.d-flex {
  display: flex;
}

.flex-gap {
  gap: 0.5rem;
}

.gallery_grid {
  float: left;
  display: inline-block;
  width: 50%;
  padding: 0px -2px;
}

.img-count {
  position: absolute;
  right: 100px;
  color: white;
  justify-content: center;
  z-index: 1;
}

.justify-end {
  justify-content: flex-end;
}

.chat-left-bubble,
.chat-right-bubble {
  max-width: 60%;
}

.chat-action-menu {
  position: relative;
}

.innerRed-div {
  padding: 8px;
  margin-left: 10px;
  width: "50%";
}

.add_item {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: "green";
  justify-content: center;
}

.img_view {
  height: "40%";
  width: "40%";
}

.gallery_grid {
  float: left;
  display: inline-block;
  width: 50%;
  padding: 0px -2px;
}

.gallery_grid img {
  width: 100%;
  height: 90px;
  object-fit: cover;
  object-position: center;
}

.gallery_grid:nth-child(even) {
  float: right;
}

.gallery_grid:nth-child(4)::before {
  content: "";
  background-color: #00000063;
  width: 100%;
  height: 96%;
}

.gallery_grid:nth-child(4) {
  position: relative;
}

.img-count {
  position: absolute;
  right: 54px;
  bottom: 40px;
  color: white;
  aligncontent: center;
  justifycontent: center;
  zindex: 1;
}

.gallery-item {
  position: relative;
  cursor: pointer;
  height: 100px;
}

.image-plus {
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100px;
}

/* .Red-div {
  width:"50%"
    } */

.AccessTime-left {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  margin-top: 5px;
  gap: 2px;
}

.AccessTime-Right {
  display: flex;
  margin-top: 5px;
  gap: 2px;
}

.convo-right {
  margin-top: 20px;
  margin-right: 15px;
}

.bg-primary {
  border-radius: 18px;
}

.innerblue-div {
  margin-right: 10px;
  padding: 8px;
}

.blue-div {
  width: "50%";
}

/*New*/
.input-container {
  width: 100%;
  padding: 0;
  margin-top: 10px;
}

.input {
  background-color: gray;
  width: 97%;
  border-radius: 4px;
  background-color: #e6ebf5;
  height: auto;
  padding-left: 1vw;
  height: 100%;
}

.chat-right {
  position: relative;
  bottom: 15px;
}

.chat-list-header {
  padding: 15px;
}

/*Profile*/

.user-details {
  width: 10vw;
  margin: auto;
  margin-top: 1vw;
}

.status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
}
.status-containeradmin {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* margin-right: 58px; */
}
.profileName {
  /* margin-top: "13px";
    margin-right: "14px"; */
  text-align: center;
  width: 100%;
}
.adminprofileName {
  text-align: center;
  width: 100%;
  padding-top: 3%;
}
.description-container {
  margin: auto;
  margin-top: 1vw;
}

.description {
  font-size: 1vw;
  text-align: left;
}

.divider-container {
  margin-top: 2vw;
}

.about-container {
  margin-top: 1vw;
  margin: auto;
}

.about-details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: #dcdfe3;
}

.details-container:first-child {
  margin-top: 0px;
}

.detail {
  margin-top: 10px;
}

.attachment-container {
  margin-top: 1vw;
  background-color: #acb8c2;
  margin: auto;
}

.attachment-details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.attachment-card {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
}

.file-details-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  /* border-color: "red"; */
}

.name-container {
}

.name-container .file-name {
}

.name-container .file-size {
  font-size: 11px;
}

.file-details {
  display: flex;
  width: 3vw;
  height: 3vw;
  background-color: #cae1ee;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.download-btn {
  margin-left: auto;
}

.gg {
  overflow-y: scroll;
  height: 62vh;
  padding: 1vw;
}

.gg::-webkit-scrollbar {
  width: 5px;
}

.gg::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

.gg::-webkit-scrollbar-thumb {
  background-color: #cad1d8;
  border-radius: 10px;
}

.gg::-webkit-scrollbar-thumb:hover {
  background-color: #d7dee7;
}

.accordian-container {
  margin-top: 20px;
}

.detail:first-child {
  margin-top: 0px;
}

.file-icon-color {
  color: "#7269ef";
}

.navbar-dark {
  display: flex;

  align-items: center;
  padding: 20px 10px;
  justify-content: space-between;
}

.nav_icons {
  z-index: 1;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.More-drop-down-right {
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  background-color: #fff;
  position: absolute;
  top: 30px;
  left: -115px;
}

.More-drop-down-left {
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  background-color: #fff;
  position: absolute;
  top: 30px;
  right: -114px;
}

.textbox-flex {
  display: flex;
  align-items: center;
  margin-top: -1px;
  /* justify-content: center; */
  cursor: pointer;
}

.send-button {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.accordian-center {
  display: flex;
  align-items: center;
}

.divider-content {
  background-color: "#e6ebf5";
  width: "50px";
  padding: "3px";
  font-size: "13px";
  border-radius: "3px";
}
.reel {
  height: 45px;
  width: 30px;
}
.middle-portion {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: "200px";
  background-color: aqua;
}
.profile-header {
  margin-top: 20px;
  padding: 15px;
}
.profile-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 200px;
  display: flex;
  align-items: end;
  width: 90px;
}

.logout-proflie {
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  z-index: 1;
  background-color: #fff;
  position: absolute;
  left: 20px;
  top: 0px;
}

.profile-status {
  position: absolute;
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  z-index: 1000;
  background-color: #fff;
  width: 150px;
  margin-left: 70px;
  top: 240px;
  padding: 2px;
  color: #6b7a80;
}

.profile-privacy-status {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.profilepic-Edit {
  position: absolute;
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  z-index: 1000;
  width: 44%;
  margin-left: 131px;
  margin-top: 4px;
  background-color: #fff;
  padding: 3px;
  top: 88px;
  color: #6b7a80;
}

.profilepic-Edit {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.profile-privacy-status {
  position: absolute;
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  z-index: 1000;
  width: 44%;
  margin-left: 131px;
  margin-top: 4px;
  background-color: #fff;
  padding: 3px;
  top: 193px;
  color: #6b7a80;
}

.profile-status {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.profile-privacy-group {
  position: absolute;
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  z-index: 1000;
  width: 44%;
  margin-left: 131px;
  margin-top: 4px;
  background-color: #fff;
  padding: 3px;
  top: 140px;
  color: #6b7a80;
}

/* .profile-privacy-group {
      -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
     */

.MuiDrawer-root.MuiDrawer-docked.css-wbbkz5-MuiDrawer-docked {
  z-index: 1;
}

.MuiBox-root.css-14v2pou {
  margin-bottom: 20em;
}

.side-nav-profileMore {
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  background-color: #fff;
  top: 70px;
  position: absolute;
  z-index: 9999;
  left: 260px;
  padding: 5px;
}

.side-nav-profileMore {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*Settings*/
.settings-container {
  padding: 20px;
  margin-top: 10px;
}

.profile-avatar-container {
  display: flex;
  width: 0vw;
  height: 5vw;
  margin: auto;
  justify-content: center;
  align-items: center;
  background-color: #e4e8eb;
  border-radius: 5vw;
  margin-top: 20px;
  border: 1px solid #f2f3f8;
}

.accordians-container::-webkit-scrollbar {
  width: 5px;
}

.accordians-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

.accordians-container::-webkit-scrollbar-thumb {
  background-color: #cad1d8;
  border-radius: 10px;
}

.accordians-containe::-webkit-scrollbar-thumb:hover {
  background-color: #d7dee7;
}

.privacy {
  display: flex;
  justify-content: space-between;
  align-items: "center";
  margin-top: 30px;
}

.name-edit {
  display: flex;
  justify-content: space-between;
  align-items: "center";
}

.profilepic-Edit {
  position: absolute;
  -webkit-box-shadow: 0 -3px 8px rgba(10, 19, 30, 0.12);
  z-index: 1000;
  width: 44%;
  margin-left: 131px;
  margin-top: 4px;
  background-color: #fff;
  padding: 3px;
  top: 88px;
  color: #6b7a80;
}

.profilepic-Edit {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
